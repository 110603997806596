import React, { useState, useEffect, useRef } from 'react';
import { YMaps, Map, Placemark, SearchControl, TrafficControl, Clusterer } from '@pbe/react-yandex-maps';
import paymentCash from '../images/payment_cash.png';
import paymentMir from '../images/payment_mir.png';
import paymentMaster from '../images/payment_masterCard.png';
import paymentVisa from '../images/payment_visa.png';
import paymentSbp from '../images/payment_sbp.png';
import { Button, Drawer, Flex, Modal, Space, Typography } from 'antd';
const { Text, Title } = Typography;

const getPlacemarks = async () => {
  const url = `https://yashashin-api.ru/placemarks`;

  try {
    const res = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      const errorData = await res.json();
      throw { status: res.status, message: errorData.message };
    }

    return await res.json();
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
    throw error;
  }
};

const createReq = async (body) => {
  const url = `https://yashashin-api.ru/form-request`;

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!res.ok) {
      const errorData = await res.json();
      throw { status: res.status, message: errorData.message };
    }

    return await res.json();
  } catch (error) {
    console.error("Ошибка при создании запроса:", error);
  }
};

function FormMap() {
  const [placemarks, setPlacemarks] = useState([]);
  const [activePlacemark, setActivePlacemark] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [popupData, setPopupData] = useState({ name: '', shedule: '', address: '' });
  const [visiblePlacemarks, setVisiblePlacemarks] = useState([]);
  const [selectShin, setSelectShin] = useState({});
  const [status, setStatus] = useState(false);
  const [listIsOpen, setListIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const mapRef = useRef(null);
  const placemarkRefs = useRef({});

  useEffect(() => {
    const fetchPlacemarks = async () => {
      try {
        const data = await getPlacemarks();
        setPlacemarks(data.sort((a, b) => a.name.localeCompare(b.name)));
      } catch (error) {
        console.error("Ошибка при установке данных:", error);
      }
    };

    fetchPlacemarks();
  }, []);

  useEffect(() => {
    if (placemarks.length !== 0) {
      window.selectService = (id) => {
        setPopupData(placemarks.find(x => x.id === id));
        setIsOpen(true);
      }
    }

  }, [placemarks]);

  function handleSubmit() {
    setStatus(true);

    createReq(selectShin).finally(() => {
      setStatus(false);
    });
  }

  function handleSelect(e) {
    e.preventDefault();

    const body = {
      id: 1, ...popupData, request: { desc: '4 шины Pirelli 245/45/18 зима шипы' }
    };

    setSelectShin(body);

    handleCloseButton();
  }

  function handleReset() {
    setSelectShin({});
  }

  const handleItemClick = (place) => {
    setPopupData(placemarks.find(x => x.id === place.id));
    setIsOpen(true);
    setListIsOpen(false);
    onClose();
  };

  const handleBalloonClick = (id) => {
    setPopupData(placemarks.find(x => x.id === id));
    setIsOpen(true);
  };

  function handleCloseButton() {
    setIsOpen(false);
    setPopupData({ name: '', shedule: '', address: '' });
  }

  const handleBoundsChange = (e, map) => {
    const bounds = map.getBounds();

    const visible = placemarks.filter(({ coords }) => {
      const [lat, lon] = coords.split(',').map(Number);
      return (
        lat >= bounds[0][0] && lat <= bounds[1][0] &&
        lon >= bounds[0][1] && lon <= bounds[1][1]
      );
    });

    setVisiblePlacemarks(visible);
  };

  return (
    <div className='formMap'>
      <div className='service__wrapp'>
        <YMaps>
          <Map
            instanceRef={mapRef}
            defaultState={{
              center: [55.753600, 37.621184],
              zoom: 10,
              controls: ['zoomControl', 'fullscreenControl']
            }}
            modules={['control.ZoomControl', 'control.FullscreenControl']}
            width='100%'
            height='100%'
            onBoundsChange={(e) => handleBoundsChange(e, e.get('target'))}
          >
            <SearchControl options={{
              float: 'left'
            }} />
            <TrafficControl options={{
              float: 'right'
            }} />
            <Clusterer options={{
              preset: 'islands#blueIcon',
              groupByCoordinates: false
            }}>
              {placemarks.map((place) => {
                const [lat, lon] = place.coords.split(',').map(Number);
                return (
                  <Placemark
                    key={place.id}
                    geometry={[lat, lon]}
                    instanceRef={(ref) => {
                      if (ref) {
                        placemarkRefs.current[place.id] = ref;
                      }
                    }}
                    options={{
                      preset: 'islands#blueIcon',
                      iconColor: activePlacemark === place.id ? '#025aa5' : '#0275d8',
                    }}
                    onClick={() => handleBalloonClick(place.id)}
                  />
                );
              })}
            </Clusterer>
          </Map>
        </YMaps>
        <button className='service__show-toggle' onClick={showDrawer}>
        </button>

        <Drawer title='Шиномонтажи' onClose={onClose} open={open}>
          {visiblePlacemarks.map((item, index) => {
            return <li className='service-item' key={index} onClick={() => handleItemClick(item)}>
              <Title level={4}>{item.name}</Title>
              <Text>{item.address}</Text>
              <Text>{item.schedule}</Text>
            </li>
          })}
        </Drawer>

        <button className='service__show-button' type='button' onClick={() => setListIsOpen(true)}><span></span><p>Списком</p></button>

        <div className='select' style={{ 'display': selectShin.name ? 'block' : 'none' }}>
          <div className='select__wrapp'>
            <Title level={5}>Выбранный шиномонтаж:</Title>
            <Flex>
              <Text>{selectShin.name ? `«${selectShin.name}»` : ''}</Text>
            </Flex>
            <Flex>
              <Text>{selectShin.address ? selectShin.address : ''}</Text>
            </Flex>
            <Flex style={{ marginBottom: 14 }}>
              <Text>{selectShin.schedule ? selectShin.schedule : ''}</Text>
            </Flex>
          </div>
          <Flex gap='large' justify='end'>
            <Button type='primary' loading={status} onClick={handleSubmit}>Оформить</Button>
            <Button onClick={handleReset}>Сбросить</Button>
          </Flex>
        </div>

        <Modal open={listIsOpen} title='Все' footer={[]} onCancel={() => setListIsOpen(false)} style={{
          top: 10, maxHeight: '80%'
        }}>
          <ul className='popup__list'>
            {visiblePlacemarks.map((item, index) => {
              return <li className='service-item' key={index} onClick={() => handleItemClick(item)}>
                <Title level={4}>{item.name}</Title>
                <Text>{item.address}</Text>
                <Text>{item.schedule}</Text>
              </li>
            })}
          </ul>
        </Modal>

        <Modal open={isOpen} footer={[]} width={800} centered onCancel={() => setIsOpen(false)}>
          <form id='form' className='popup__form' onSubmit={handleSelect} noValidate>
            <div className='popup__detail'>
              <div className='popup__detail-1'>
                <img src='/shinLogo.png'></img>
              </div>
              <div className='popup__detail-2'>
                <h5>{popupData.name}</h5>
                <p>{popupData.address}</p>
                {Math.round(Math.random()) + 1 === 1 ? <p style={{ 'color': '#5cb85c' }}>Запись возможна!</p> : <p style={{ 'color': 'rgb(205, 133, 63)' }}>Сейчас только живая очередь</p>}
                <p>{popupData.schedule}</p>
                <a href='https://goroshina.ru/fitting' target='_blank'>Прайслист</a>
                <div className='popup__payments'>
                  <div>
                    <img src={paymentCash}></img>
                  </div>
                  <div>
                    <img src={paymentMir}></img>
                  </div>
                  <div>
                    <img src={paymentMaster}></img>
                  </div>
                  <div>
                    <img src={paymentVisa}></img>
                  </div>
                  <div>
                    <img src={paymentSbp}></img>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <Flex justify='end'>
            <Button type='primary' size='large' onClick={handleSelect}>Выбрать</Button>
          </Flex>
        </Modal>
      </div>
    </div >
  );
}

export default FormMap;