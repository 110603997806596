import React, { useState, useEffect } from 'react';
import ContainerPopup from './ContainerPopup';
import RequestPopup from './CreatePopup';
import { useRole } from '../contexts/RoleContext';
import { Button, Space, Table } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

function Admin(props) {
  const { role } = useRole();
  const [data, setData] = useState([]);
  const [adminDetailsModalIsOpen, setAdminDetailsModalIsOpen] = useState(false);
  const [adminDetailsModalData, setAdminDetailsModalData] = useState({});
  const [createPopupIsOpen, setCreatePopupIsOpen] = useState(false);
  const [resStatus, setResStatus] = useState(false);
  const [pagination, setPagination] = useState([]);

  if (role !== 'admin')
    return null;

  const columns = [
    {
      title: 'Ид',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
    },
    {
      title: 'Расписание',
      dataIndex: 'schedule',
    },
    {
      title: 'Координаты',
      dataIndex: 'coords',
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
    },
    {
      title: 'Прочее',
      dataIndex: 'details',
      align: 'center',
      render: (value, record) => { return <Button ghost type='primary' size='large' icon={<InfoCircleOutlined />} onClick={() => fileButtonHandler(record.id)}></Button > },
    },
  ];

  function getData(api, param = { offset: 0 }) {
    setResStatus(true);

    api.getAllCompany(param).then(res => {
      setData(res.data);
    })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setResStatus(false);
      });
  }

  function fileButtonHandler(id) {
    setAdminDetailsModalIsOpen(true);
    setAdminDetailsModalData({ id: id });
  }

  function refreshHandler() {
    getData(props.Api);
  }


  function handlePageChange(page) {
    getData(props.Api, { offset: 10 * (page - 1) });
  }

  useEffect(() => {
    if (props.Api)
      getData(props.Api);
  }, [props.Api]);

  return (
    <div className='content'>
      <div className='certificate'>
        <Space className='certificate__buttons'>
          <Button type='primary' onClick={() => setCreatePopupIsOpen(true)}>Создать организацию</Button>
          <Button onClick={refreshHandler}>Обновить таблицу</Button>
        </Space>
        {resStatus ? <span className='loader' /> : <Table dataSource={data} columns={columns} />}

        <RequestPopup Api={props.Api} isOpen={createPopupIsOpen} popupHandler={setCreatePopupIsOpen} refreshHandler={refreshHandler} />

        <ContainerPopup Api={props.Api} isOpen={adminDetailsModalIsOpen} popupHandler={setAdminDetailsModalIsOpen} popupData={adminDetailsModalData}
          setPopupData={setAdminDetailsModalData} refreshHandler={refreshHandler} />
      </div>
    </div>
  );
};

export default Admin;