import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRole } from '../contexts/RoleContext';
import { Layout, Menu } from 'antd';
const { Sider } = Layout;
import { UserOutlined, BookOutlined, ContainerOutlined, ConsoleSqlOutlined } from '@ant-design/icons';

function Sidebar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { role } = useRole();

  const links = new Map([
    ['/profile', '1'],
    ['/company', '2'],
    ['/request', '3'],
    ['/admin', '4'],
  ]);

  function getItem(label, key, icon, onClick) {
    return {
      key,
      icon,
      label,
      onClick
    };
  }

  const items = [
    getItem('Профиль', '1', <UserOutlined />, () => navigate('/profile')),
    role === 'moder' ? getItem('Организация', '2', <BookOutlined />, () => navigate('/company')) : null,
    getItem('Заявки', '3', <ContainerOutlined />, () => navigate('/request')),
    role === 'admin' ? getItem('Администрирование', '4', <ConsoleSqlOutlined />, () => navigate('/admin')) : null,
  ];

  return (
    <Sider className={`${props.isOpen ? 'sidebar sidebar_open' : 'sidebar'}`}
      collapsible collapsed={props.isMini} onCollapse={props.handleMiniClick}>
      <Menu theme='dark' defaultSelectedKeys={[links.get(location.pathname)]} mode='inline' items={items} />
    </Sider>
  );
};

export default Sidebar;
